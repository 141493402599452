import React, { useEffect, useState } from "react";
import DataTable from 'react-data-table-component'
import Loader from "../loader/Loader";
import axios from "axios";
import { API } from "../../store/config/ApiUrl";
import { resultColumns } from "../utils/ResultData";
import { toast } from "react-toastify";

let varSortDirection = "-1";
let VarSortBy = "created_at";

const ResultTable = () => {
    const [result, setResult] = useState([]);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState("created_at");
    const [sortDirection, setSortDirection] = useState("-1")
    const [page, setPage] = useState(1);
    const [totalRows, setTotalRows] = useState(0);
    const [loader, setLoader] = useState(true);


    const customStyles = {
        headCells: {
            style: {
                background: '#2f8ec7', // Change this to your desired header color
                color: '#fff', // Text color for the header
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            },
        },
        cells: {
            style: {
                display: 'flex',
                justifyContent: 'flex-start', // Center align all values in the table cells
                alignItems: 'center',
            },
        },
    };

    useEffect(() => {
        getValuationResultDetails(page, perPage, sortBy, sortDirection)
    }, [])

    // Function to get all trade details of a logged user
    const getValuationResultDetails = async (page, perPage, sortBy, sortDirection) => {
        try {
            setLoader(true)
            const dataForToken = localStorage.getItem('okta-token-storage');
            const parsedData = JSON.parse(dataForToken);
            let userID = parsedData.accessToken?.claims?.uid;

            let res = await axios.get(`${API.valuationResult}/${userID}?page=${page}&per_page=${perPage}&sort=${sortBy}&order=${sortDirection}`)
            if (res?.status === 200) {
                setResult(res?.data?.result.data.valuationData);
                setTotalRows(res?.data?.result.data.totalCount);
                setLoader(false)
            }
            else if (res?.status === 204) {
                setResult([]);
                setLoader(false)
            }
        } catch (error) {
            if (error?.response?.data) {
                toast.error(error.response?.data?.error.message);
            }
            else {
                toast.error(error.message);
            }
        }
    }

    const handlePageChange = currentPage => {
        getValuationResultDetails(currentPage, perPage, VarSortBy ? VarSortBy : "", varSortDirection ? varSortDirection : "");
        setPage(currentPage)
    };


    const handlePerRowsChange = async (newPerPage, page) => {
        getValuationResultDetails(page, newPerPage, sortBy, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        let direction = sortDirection === "asc" ? "1" : sortDirection === "desc" ? "-1" : ""
        varSortDirection = direction;
        VarSortBy = column?.sortField;
        if (page === 1) {
            getValuationResultDetails(page, perPage, column?.sortField, direction);
        }
        setSortBy(column?.sortField);
        setSortDirection(direction)
    };


    return (
        <div className="card tableLayout">
            <Loader loading={loader} />
            {
                result &&
                <>
                    <DataTable columns={resultColumns}
                        data={result.length > 0 ? result : []}
                        theme="solarized"
                        fixedHeader
                        fixedHeaderScrollHeight='470px'
                        highlightOnHover
                        striped
                        customStyles={customStyles}
                        pagination
                        paginationServer
                        paginationTotalRows={totalRows}
                        onChangeRowsPerPage={handlePerRowsChange}
                        onChangePage={handlePageChange}
                        onSort={handleSort}
                        sortServer
                    />

                </>
            }

        </div>
    )
}

export default ResultTable;
import moment from 'moment'
export const resultColumns = [
    {
        name: "ID",
        selector: result => result?._id || "NA",
        wrap: true,
        width: "170px"
    },
    {
        name: "Created At",
        selector: result => moment(result?.created_at).format('YYYY-MM-DD') || "NA",
        sortable: true,
        sortField: 'created_at',
        wrap: true,
        width: "150px"
    },
    {
        name: "Fixed Leg Accrued",
        selector: result => result?.fixed_leg_accrued || "NA",
        wrap: true,
        width: "170px"
    },
    {
        name: "Fixed Leg Clean",
        selector: result => result?.floating_leg_clean || "NA",
        wrap: true,
        width: "170px"
    },
    {
        name: "Pv",
        selector: result => result?.pv || "NA",
        wrap: true,
        width: "170px"
    },
    {
        name: "Floating Leg Accrued",
        selector: result => result?.floating_leg_accrued || "NA",
        wrap: true,
        width: "200px"
    },
    {
        name: "Floating Leg Clean",
        selector: result => result?.fixed_leg_clean || "NA",
        wrap: true,
        width: "170px"
    },
    {
        name: "Currency",
        selector: result => result?.currency || "NA",
        wrap: true,
        width: "110px"
    },
    {
        name: "Par Rate",
        selector: result => result?.par_rate || "NA",
        wrap: true,
        width: "170px"
    },
    {
        name: "Swap Bpv",
        selector: result => result?.swap_bpv || "NA",
        wrap: true,
        width: "170px"
    },
    {
        name: "Fixed Leg Pv",
        selector: result => result?.fixed_leg_pv || "NA",
        wrap: true,
        width: "170px"
    },
    {
        name: "Doc Id",
        selector: result => result?.doc_id || "NA",
        wrap: true,
        width: "170px"
    },
]

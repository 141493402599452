import React from 'react';
import { useOktaAuth } from '@okta/okta-react';
import { Link, useHistory  , useNavigate} from 'react-router-dom';
import { toast } from 'react-toastify';

const NavBar = (props) => {
  const { authState, oktaAuth } = useOktaAuth();
  const history = useHistory();

const handleLogout = async (e) => {
  e.preventDefault();
  try {
    await oktaAuth.signOut();
    oktaAuth.tokenManager.clear();
    localStorage.clear();
    toast.success('You have been logged out');
    history.push('https://dev-20733035.okta.com/');
  } catch (err) {
    console.error('Logout failed with error', err);
    toast.error('Logout failed. Please try again later.');
  }
};
  return (
    <>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light fixed-top bg-white">
          <div className="container" id="navbar">
            <Link to="/" className="navbar-brand derivatives-logo-brand">
              <img src="../../../assets/images/derivativesLogo.png" alt="Derivatives Logo" />
              <h3 className="title font-light">Derivatives Pricing</h3>
            </Link>
            <button
              className="navbar-toggler"
              type="button"
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarSupportedContent">
              <form>
                <ul className="navbar-nav me-auto mb-2 mb-lg-0 mt-3">
                  <li className="nav-item p-1">
                    <Link to="/" className={`linkHeader nav-link ${props.active ? props.active : "" }`}>
                      Dashboard
                    </Link>
                  </li>
                  <li className="nav-item p-1">
                    <Link to="" className="linkHeader nav-link">
                      About
                    </Link>
                  </li>
                  <li className="nav-item p-1">
                    <Link to="" className="linkHeader nav-link">
                      Contact Us
                    </Link>
                  </li>
                  <li className="nav-item p-1">
                    <Link to="" className="linkHeader nav-link">
                      Your Account
                    </Link>
                  </li>
                  {authState.isAuthenticated && (
                    <li className="nav-item p-1">
                      <button
                        className="linkHeader nav-link"
                        onClick={handleLogout}
                      >
                        Logout
                      </button>
                    </li>
                  )}
                </ul>
              </form>
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default NavBar;

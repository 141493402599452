
// const BASE_URL = "http://54.65.43.69:3012/api/v1"
// const BASE_URL = "https://www.idpv.co.uk/api/v1/users"
// const BASE_URL = "https://www.dev.idpv.co.uk/api/v1/users"
// const BASE_URL_LOGIN = "http://10.10.9.24:5000/users"
// const BASE_URL = "http://10.10.9.24:3000/users"

const BASE_URL = "https://www.idpv.co.uk/api/v1/users"


export const API = {
  getData: `${BASE_URL}/get-trade-data`,
  downloadErrorLogData: `${BASE_URL}/download-errorlog-csv`,
  insertData: `${BASE_URL}/insert-csv-ui`,
  valuationResult: `${BASE_URL}/get-valuation-result`,
  getUploadHistory: `${BASE_URL}/get-perticular-upload-history`,
  getErrorParticularRecord: `${BASE_URL}/get-error-record`,
  downloadTradeFile: `${BASE_URL}/download-trade-file`,
  getTradesByDocId: `${BASE_URL}/get-trades-by-doc-id`,
  getSpecificUserbyId: `${BASE_URL}/get-specific-user`,
  submitFileForValuation: `${BASE_URL}/submit-file-for-valuation`
}













import React from "react";

const Footer = () => {
    return (
        <>
            <footer className="bg-dark text-center text-white pt-3 pb-3 font-light footer">
                <div className="container">
                    {/* <section className="">
                        <div className="row">
                            <div className="col-lg-4 col-md-4 mb-4 mb-md-0">

                                <ul className="list-unstyled mb-0 text-left">
                                    <li>
                                        <a href="#!" className="text-white">Privacy Notice</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Cookie Notice</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Site map</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Accessibility</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">FAQ</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-4 mb-4 mb-md-0">

                                <ul className="list-unstyled mb-0 text-left">
                                    <li>
                                        <a href="#!" className="text-white">Legal and regulatory terms of use</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Legal notices</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Disclaimer</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Modern Slavery Statement</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Memberships</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Legal and Regulatory</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Brexit Information</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Occupational Pension Schemes (Administration And Disclosure) Regulations 2018</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Conflicts of Interest</a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">Benchmark Disclaimer</a>
                                    </li>
                                </ul>
                            </div>

                            <div className="col-lg-4 col-md-4 mb-4 mb-md-0">

                                <ul className="list-unstyled mb-0 d-flex social-links-footer">
                                    <li>
                                        <a href="#!" className="text-white">
                                            <img src="../../../assets/images/linkedin-icons.svg" alt="Linkedin Icons" /></a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">
                                            <img src="../../../assets/images/twitter-icons.svg" alt="Linkedin Icons" /></a>
                                    </li>
                                    <li>
                                        <a href="#!" className="text-white">
                                            <img src="../../../assets/images/instagram-icons.svg" alt="Linkedin Icons" /></a>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </section> */}
                    <div className="text-center copyright" >
                        <p>© 2023 Derivatives Valuation</p>
                    </div>
                </div>
            </footer>
        </>
    )
}


export default Footer;
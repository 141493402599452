import React from 'react';
import { Link } from "react-router-dom";

const Card = () => {
    return (
        <>



            <div className="row">

                <div className="col-md-4 col-lg-3">
                    <div className="card db-card-box">
                        <Link to="/uploadTradeFile" className='link'>
                            <div className="card-body">
                                <i><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-file-earmark-text" viewBox="0 0 16 16">
                                    <path d="M5.5 7a.5.5 0 0 0 0 1h5a.5.5 0 0 0 0-1h-5zM5 9.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h2a.5.5 0 0 1 0 1h-2a.5.5 0 0 1-.5-.5z" />
                                    <path d="M9.5 0H4a2 2 0 0 0-2 2v12a2 2 0 0 0 2 2h8a2 2 0 0 0 2-2V4.5L9.5 0zm0 1v2A1.5 1.5 0 0 0 11 4.5h2V14a1 1 0 0 1-1 1H4a1 1 0 0 1-1-1V2a1 1 0 0 1 1-1h5.5z" />
                                </svg></i>
                                <h5 className="card-title font-semibold mb-0"> File Upload History</h5>
                            </div>
                        </Link>
                    </div>
                </div>


                <div className="col-md-4 col-lg-3">
                    <div className="card db-card-box">
                        <Link to="/tradeDetail" className='link'>
                            <div className="card-body">
                                <i className='light-blue'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-laptop" viewBox="0 0 16 16">
                                    <path d="M13.5 3a.5.5 0 0 1 .5.5V11H2V3.5a.5.5 0 0 1 .5-.5h11zm-11-1A1.5 1.5 0 0 0 1 3.5V12h14V3.5A1.5 1.5 0 0 0 13.5 2h-11zM0 12.5h16a1.5 1.5 0 0 1-1.5 1.5h-13A1.5 1.5 0 0 1 0 12.5z" />
                                </svg></i>
                                <h5 className="card-title font-semibold mb-0">Trade Details</h5>
                            </div>
                        </Link>
                    </div>
                </div>

                <div className="col-md-4 col-lg-3">
                    <div className="card db-card-box">
                        <Link to="/valuationResult" className='link'>
                            <div className="card-body">
                                <i className='light-vg'><svg xmlns="http://www.w3.org/2000/svg" width="30" height="30" fill="currentColor" className="bi bi-search" viewBox="0 0 16 16">
                                    <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                                </svg></i>
                                <h5 className="card-title font-semibold mb-0"> Valuation Results</h5>
                            </div>
                        </Link>
                    </div>
                </div>


            </div>
        </>
    )
}

export default Card;
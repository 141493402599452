import axios, { CancelToken, isCancel } from "axios";
import React, { useState, useRef, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { API } from "../../store/config/ApiUrl";
import NavBar from "../nav/NavBar";
import FileUploadLoader from "../loader/FileUploadLoader";
import axiosHelper from "../utils/axiosHelper";
import moment from 'moment'
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import DataTable from "react-data-table-component";
import Loader from "../loader/Loader";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faFolderOpen, faCheckSquare, faDownload } from '@fortawesome/free-solid-svg-icons'
import Swal from "sweetalert2";

let varSortDirection = "-1";
let VarSortBy = "created_at";

const UploadTradeFile = () => {

    const history = useHistory();
    const [fileData, setFileData] = useState();
    const [loading, setLoading] = useState(false);
    const [loader, setLoader] = useState(true);
    const [tradeData, setTradeData] = useState([]);
    const [countData, setCountData] = useState();
    const [perPage, setPerPage] = useState(10);
    const [page, setPage] = useState(1);
    const [sortBy, setSortBy] = useState("created_at");
    const [sortDirection, setSortDirection] = useState("-1")
    const [totalRows, setTotalRows] = useState(0);
    const cancelFileUpload = useRef(null);

    const handleUploadFile = (e) => {
        const files = e.target.files[0];
        setFileData(files)
    }

    useEffect(() => {
        getUploadHistoryDetails(page, perPage, sortBy, sortDirection);
    }, []);

    const customStyles = {
        headCells: {
            style: {
                background: '#2f8ec7', // Change this to your desired header color
                color: '#fff', // Text color for the header
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-start"
            },
        },
        cells: {
            style: {
                display: "flex",
                justifyContent: "flex-start", // Center align all values in the table cells
                alignItems: "center",
            },
        },
    };

    // Table Columns
    const ErrorColumns = [
        {
            name: "Doc ID",
            selector: tradeData => tradeData?.["doc_id"] || "NA",
            // sortable: true,
            wrap: true

        },
        {
            name: "Date",
            selector: tradeData => moment(tradeData?.["created_at"]).format('YYYY-MM-DD HH:mm:ss') || "NA",
            sortable: true,
            sortField: 'created_at',
            wrap: true

        },
        {
            name: "File Name",
            selector: tradeData => tradeData?.["file_name"] || "NA",
            sortable: true,
            sortField: 'file_name',
            cell: row => (
                <div>
                    <OverlayTrigger
                        placement="top"
                        overlay={
                            <Tooltip id={`tooltip-${row._id}`} className="message-tooltip">
                                {row.file_name}
                            </Tooltip>
                        }
                        flip
                        trigger={['hover', 'focus']}
                    >
                        <span className="message-text">
                            {row.file_name.substring(0, 15)}{row.file_name.length > 15 ? '...' : ''}
                        </span>
                    </OverlayTrigger>
                </div>
            ),
        },
        {
            name: "Total Rows",
            selector: tradeData => tradeData?.total_records || "0",
            wrap: true,
            // sortable: true
        },
        {
            name: "Success",
            selector: tradeData => (tradeData?.total_records - tradeData?.error_records) || "0",
            // sortable: true
        },
        {
            name: "Fail",
            selector: tradeData => tradeData?.["error_records"] || "0",
        },
        {
            name: "Action",
            wrap: true,
            width: "500px",
            cell: (row) => {
                return (<div className="tb-btn">
                    <button onClick={() => {
                        Swal.fire({
                            // title: 'Are you sure?',
                            text: "Do you want to save the trade file ?",
                            html:
                                `<div class="swal-popup-section"><img src="/assets/images/excel-file.png" alt="excel-file" />
                            <div class="right-swal-content"><h3>Do you want to save or cancel this file ?</h3>
                            <span><i>File Name:</i>  ${row.file_name}</span> <span><i>File Type&nbsp;&nbsp;:</i> text/csv</span>
                            </div></div>`,
                            showCancelButton: true,
                            confirmButtonColor: '#07b0f3',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Save'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                downloadTradeFile(row.file_url, row.file_name)
                            }
                        })

                    }} className="btn btn-outline-primary me-2">
                        Download File
                    </button>

                    <button onClick={() => {
                        let errorFileName = row?.error_file_url ? row?.error_file_url?.split("/")[4] : "error.xlsx";
                        Swal.fire({
                            // title: 'Are you sure?',
                            text: "Do you want to save the log file ?",
                            // icon: 'question',
                            html:
                                `<div class="swal-popup-section"><img src="/assets/images/excel-file.png" alt="excel-file" />
                            <div class="right-swal-content"><h3>Do you want to save or cancel this file ?</h3>
                            <span><i>File Name:</i>  ${errorFileName}</span> <span><i>File Type:</i>application/vnd.ms-excel</span>
                            </div></div>`,
                            showCancelButton: true,
                            confirmButtonColor: '#07b0f3',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Save'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                downloadErrorLog(row?.error_file_url)
                            }
                        })
                    }
                    } disabled={row?.["error_records"] === 0 ? true : false} className="btn btn-outline-primary me-2">
                        Error Log
                    </button>

                    <button onClick={() => {
                        history.push(`/tradeDetail?doc_id=${row.doc_id}`)
                    }} disabled={!(row?.total_records - row?.error_records)} className="btn btn-outline-primary me-2">
                        View Trades
                    </button>

                    <button onClick={() => {
                        Swal.fire({
                            // title: 'Are you sure?',
                            text: "Do you want to submit the file for valuation ?",
                            icon: 'question',
                            showCancelButton: true,
                            confirmButtonColor: '#07b0f3',
                            cancelButtonColor: '#d33',
                            confirmButtonText: 'Submit'
                        }).then((result) => {
                            if (result.isConfirmed) {
                                submitForValuation(row?.doc_id, row?._id, row?.created_by);
                            }
                        })
                    }} disabled={row.is_ready_for_valuation || !(row?.total_records - row?.error_records)} className={`btn btn-outline-primary me-2 ${row.is_ready_for_valuation === true ? "" : "submit-btn"}`}>
                        {row.is_ready_for_valuation === true ? "Submitted" : "Submit"}
                    </button>

                    {/* <a href={`/ViewErrorLogById/${row.id}`}> View Erroneous Record</a> */}
                </div>);
            },
        }

    ]

    // Function to upload file 
    const insertData = async (e) => {
        e.preventDefault();
        if (!fileData) {
            toast.error('Please select a file to upload');
            return;
        }
        if (fileData.type === "text/csv") {
            setLoading(true);
            const formData = new FormData();
            const options = {
                cancelToken: new CancelToken(
                    cancel => (cancelFileUpload.current = cancel)
                )
            };
            const dataForToken = localStorage.getItem('okta-token-storage');
            const parsedData = JSON.parse(dataForToken);
            let organization = localStorage.getItem('organization');
            let userID = parsedData.accessToken?.claims?.uid;
            let userName = parsedData.idToken?.claims?.name;
            formData.append('uid', userID);
            formData.append('organization', organization);
            formData.append('username', userName);
            formData.append('file-upload', fileData);
            try {
                const response = await axiosHelper.post(API.insertData, formData, options);
                if (response?.status === 200) {
                    setFileData(null);
                    setCountData(response.data.result);
                    toast.success(response.data.result.message);
                    getUploadHistoryDetails(page, perPage, sortBy, sortDirection);
                    let uploadModal = document?.getElementById('close-modal');
                    uploadModal.click();
                }
                setLoading(false); // stop the loader here
            } catch (err) {
                if (err?.response?.data?.message) {
                    setFileData()
                    setLoading(false)
                    toast.error(err?.response?.data?.message)
                }
                else if (err?.response?.data) {
                    toast.error(err?.response?.data?.error?.message)
                    setFileData()
                    setLoading(false)
                }
                else if (err.message) {
                    setFileData()
                    toast.error(err?.message)
                }
            };
        } else {
            toast.error("Only .csv file are allowed");
        }
    }

    // Function to cancel file upload
    const cancelUpload = (e) => {
        e.preventDefault()
        if (cancelFileUpload.current)
            setLoading(false)
        cancelFileUpload.current("You have cancelled the file upload.");
    };

    // Function to get Upload history of a logged user
    const getUploadHistoryDetails = async (page, perPage, sortBy, sortDirection) => {

        console.log(page, perPage, sortBy, sortDirection, "getUploadHistoryDetails");
        const dataForToken = localStorage.getItem('okta-token-storage');
        const parsedData = JSON.parse(dataForToken);
        let userID = parsedData.accessToken?.claims?.uid
        try {
            setLoader(true);
            const result = await axiosHelper.get(`${API.getUploadHistory}/${userID}?page=${page}&per_page=${perPage}&sort=${sortBy}&order=${sortDirection}`);

            if (result?.status === 200) {
                setTradeData(result?.data?.result?.data?.resData);
                setTotalRows(result?.data?.result?.data?.totalCount);
                setLoader(false)
            }
            if (result.status === 204) {
                setTradeData([]);
                setLoader(false);
            }
        } catch (err) {
            if (err?.response?.data) {
                toast.error(err.response?.data?.error.message);
            }
            else if (err.message) {
                toast.error(err.message);
            }
        }
    };

    // Function to Download the original trade file
    const downloadTradeFile = async (fileKey, fileName) => {
        if (fileKey) {
            let key = fileKey?.split('/');
            let awsFileKey = key?.slice(3).join('/')
            let keyObj = {
                key: awsFileKey
            }
            try {
                axios.post(`${API.downloadTradeFile}`, keyObj, { responseType: 'arraybuffer' }).then(async (response) => {
                    const blob = new Blob([response.data], { type: 'application/octet-stream' });
                    const url = URL.createObjectURL(blob);
                    const link = document.createElement("a");
                    link.href = url;
                    link.setAttribute("download", `${fileName}`);
                    link.style.display = "none";
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                }).catch((err) => {
                    console.log(err);
                    if (err?.response?.data) {
                        toast.error(err.response?.data?.error?.message)
                    }
                    if (err.message) {
                        toast.error(err?.message)
                    }
                })

            } catch (error) {
                console.error('Error while downloading file:', error);
                toast.error(error?.message)
                // Handle the error here (e.g., show an error message to the user)
            }
        }

    }

    // Function to Download the Error log file of a perticular file upload
    const downloadErrorLog = async (key) => {
        try {
            axios.post(`${API.downloadErrorLogData}`, { key }, { responseType: 'arraybuffer' }).then(async (response) => {
                const blob = new Blob([response.data], { type: 'application/octet-stream' });
                const url = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.href = url;
                link.setAttribute("download", `${key ? key?.split("/")[4] : "error.xlsx"}`);
                link.style.display = "none";
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            }).catch((err) => {
                console.log(err);
                if (err?.response?.data) {
                    toast.error(err.response?.data?.error.message)
                }
                if (err.message) {
                    toast.error(err?.message)
                }
            })
        } catch (error) {
            toast.error(error.message);
            console.log(error);
        }
    };

    // Function to Submit the File for the valuation
    const submitForValuation = async (doc_id, id, created_by) => {
        try {
            let result = await axios.post(`${API.submitFileForValuation}`, {
                doc_id: doc_id,
                id: id,
                created_by: created_by
            });
            if (result.status === 200) {
                toast.success(result?.data?.result.message);
                getUploadHistoryDetails(page, perPage, sortBy, sortDirection);
            }
        } catch (error) {
            if (error?.response?.data) {
                toast.error(error.response?.data?.error?.message)
            }
            else if (error?.message) {
                toast.error(error.message);
            }
            console.log(error);
        }
    }

    const handlePageChange = currentPage => {
        getUploadHistoryDetails(currentPage, perPage, VarSortBy ? VarSortBy : "", varSortDirection ? varSortDirection : "");
        setPage(currentPage);
    };

    const handlePerRowsChange = async (newPerPage, page) => {
        getUploadHistoryDetails(page, newPerPage, sortBy, sortDirection);
        setPerPage(newPerPage);
    };

    const handleSort = async (column, sortDirection) => {
        let direction = sortDirection === "asc" ? "1" : sortDirection === "desc" ? "-1" : ""
        varSortDirection = direction;
        VarSortBy = column?.sortField;
        if (page === 1) {
            getUploadHistoryDetails(page, perPage, column?.sortField, direction);
        }
        setSortBy(column?.sortField);
        setSortDirection(direction)
    };


    return (
        <>
            <NavBar />

            <Loader loading={loader} />

            <div className="container upload-trade-content">
                <div className="row align-items-center">
                    <div className="col-md-6">
                        {countData && (
                            <div className="row mt-5">
                                <div className="col-md-4 mb-3">
                                    <div className="border border-info bx-info">
                                        <h4 className="text-info">
                                            Total Record
                                        </h4>
                                        <p className="text-info">{countData?.Total_records} <FontAwesomeIcon className="icon" icon={faFolderOpen} /></p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="border border-success bx-info">
                                        <h4 className="text-success">
                                            Success Record
                                        </h4>
                                        <p className="text-success">
                                            {countData?.Total_records - countData?.error_count}  <FontAwesomeIcon className="icon" icon={faCheckSquare} />
                                        </p>
                                    </div>
                                </div>
                                <div className="col-md-4 mb-3">
                                    <div className="border border-danger bx-info">
                                        <h4 className="text-danger">
                                            Error Record
                                        </h4>
                                        <p className="text-danger">{countData?.error_count}  {countData?.error_count > 0 && <FontAwesomeIcon className="icon" icon={faDownload} />} </p>
                                    </div>
                                </div>
                            </div>
                        )}
                    </div>

                    {/* <!-- Modal --> */}
                    <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                        <div className="modal-dialog modal-dialog-centered modal-lg">
                            <div className="modal-content modal-upload-content">
                                <div className="modal-header">
                                    <h1 className="modal-title fs-5" id="exampleModalLabel">Upload Trade File</h1>
                                    <button type="button" id="close-modal" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                                </div>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group files">
                                                {loading === false ? (
                                                    <form onSubmit={insertData} className="form-section">
                                                        <input
                                                            type="file"
                                                            id="input-file-now"
                                                            className="form-control"
                                                            accept=".csv"
                                                            onChange={(e) => handleUploadFile(e)}
                                                        />
                                                        <h5 className="uploadHeading">
                                                            Drag and Drop a trades .csv file here
                                                        </h5>
                                                        <button type="submit" className="btn btn-primary mt-5 ml-5">
                                                            Upload
                                                        </button>
                                                    </form>
                                                ) : (
                                                    <>
                                                        <div className="row loaderLayout">
                                                            <div className="col">
                                                                <FileUploadLoader loading={true} />
                                                            </div>
                                                            <h5 className="uploadHeading p-2">Uploading ......... </h5>
                                                        </div>
                                                        <button
                                                            type="button"
                                                            onClick={(e) => cancelUpload(e)}
                                                            className="btn btn-primary ml-3 cancel-btn"
                                                        >
                                                            Cancel
                                                        </button>
                                                    </>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="main-content error-grid">
                    <div className="back-btn db-content" onClick={() => {
                        window.history.back()
                    }}>
                        <i className="bi bi-arrow-left" ></i> Back
                    </div>
                    <div className="heading-section">
                        <h5 className="title heading-title">File Upload History</h5>
                        <button type="button" className="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
                            Upload New File
                        </button>
                    </div>
                    <div className="row">
                        <div className="col-12">
                            <div className="card tableLayout tb-card-content">
                                <>
                                    <div>
                                        <div>
                                            <DataTable
                                                data={tradeData.length > 0 ? tradeData : []}
                                                columns={ErrorColumns}
                                                className="-striped -highlight"
                                                highlightOnHover
                                                customStyles={customStyles}
                                                pagination
                                                paginationServer
                                                paginationTotalRows={totalRows}
                                                onChangeRowsPerPage={handlePerRowsChange}
                                                onChangePage={handlePageChange}
                                                onSort={handleSort}
                                                sortServer
                                            />
                                        </div>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
            </div >

        </>
    )
}

export default UploadTradeFile;

import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap/dist/js/bootstrap'
import "./App.css";
import TradeValuationResult from "./components/trade/TradeValuationResult";
import { Security, SecureRoute, LoginCallback } from "@okta/okta-react";
import ViewErrorLogById from "./components/trade/ViewErrorLogById";
import { OktaAuth, toRelativeUrl } from "@okta/okta-auth-js";
import UploadTradeFile from "./components/trade/UploadTrade";
import TradeDetail from "./components/trade/TradeDetail";
import { Route, useHistory } from "react-router-dom";
import DashBoard from "../src/dashboard/DashBoard";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";


axios.interceptors.request.use(function (config) {
  // Do something before request is sent
  const dataForToken = localStorage.getItem("okta-token-storage");
  const parsedData = JSON.parse(dataForToken);
  let authToken = parsedData && parsedData.idToken && parsedData.idToken.idToken;

  if (authToken) {
    config.headers.Authorization = `${authToken}`
  }
  return config;
}, function (error) {
  // Do something with request error
  return Promise.reject(error);
});


function App() {
  const history = useHistory();
  const onAuthRequired = () => {
    history.push("/");
  };

  const oktaAuth = new OktaAuth({
    issuer: "https://dev-20733035.okta.com/oauth2/default",
    clientId: "0oa7tci22wsdhYKTi5d7",
    redirectUri: window.location.origin + "/login/callback",
    onAuthRequired: onAuthRequired,
    pkce: true,
  });

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri, window.location.origin));
  };

  return (
    <>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="dark"
      />
      <Security oktaAuth={oktaAuth} restoreOriginalUri={restoreOriginalUri}>

        <SecureRoute
          path="/"
          exact={true}
          component={DashBoard}
        />

        <SecureRoute
          path="/tradeDetail"
          exact={true}
          component={TradeDetail}
        />

        <SecureRoute
          path="/uploadTradeFile"
          exact={true}
          component={UploadTradeFile}
        />

        <SecureRoute
          path="/valuationResult"
          exact={true}
          component={TradeValuationResult}
        />

        {/* <SecureRoute
          path="/ViewErrorLogById/:id"
          exact={true}
          component={ViewErrorLogById}
        /> */}
        <Route path="/login/callback" component={LoginCallback} />
      </Security>
    </>
  );
}

export default App;

import React, { useState } from "react";
import Card from "../components/card/Card";
import NavBar from "../components/nav/NavBar";
import { useEffect } from "react";
import axios from "axios";
import { API } from "../store/config/ApiUrl";
import { toast } from "react-toastify";

const DashBoard = () => {
    const [dataSet, setDataSet] = useState(null);
    const dataForToken = localStorage.getItem('okta-token-storage');
    const parsedData = JSON.parse(dataForToken);
    let userID = parsedData.accessToken?.claims?.uid

    useEffect(() => {
        const fetchData = async () => {
            const dataForToken = localStorage.getItem('okta-token-storage');
            if (!dataForToken) {
                return;
            }
            const parsedData = JSON.parse(dataForToken);
            setDataSet(parsedData);
        }
        fetchData();
        getUserOrganization();
    }, []);


    // Function to get Entity | Organization of a logged or a perticular user
    const getUserOrganization = () => {
        try {
            axios.get(`${API.getSpecificUserbyId}/${userID}`).then((res) => {
                if (res.status === 200) {
                    localStorage.setItem("organization", res?.data?.result?.data?.profile?.organization);
                }
            }).catch((err) => {
                if (err?.response?.status === 401) {
                    toast.error(err.response.data.message)
                }
            })
        } catch (error) {

        }

    }

    return (
        <>

            <NavBar active={"dashboard-active"} />
            <div className="main-content">
                <div className="container">
                    <div className="db-content">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="heading-section">
                            <h5 className="title heading-title">User Dashboard</h5>
                            </div>
                        </div>
                        <Card />
                    </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default DashBoard;
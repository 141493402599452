import React from 'react';
import { PropagateLoader } from 'react-spinners'

function Loader(props) {
    if (props.loading) {
        return (
            <div style={{
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: 'rgba(255, 255, 255, 0.8)',
                zIndex: 999999
            }}><PropagateLoader color={'#2f8ec7'} width={'7px'} loading={true} /></div>
        );
    }
}

export default React.memo(Loader);
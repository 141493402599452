import React from 'react';
import NavBar from '../nav/NavBar';
import ResultTable from '../table/ResultTable';

const TradeValuationResult = () => {
    return (
        <>
            <NavBar />
            <div className="main-content">
                <div className="container db-content">
                    <div className="row mb-5">
                        <div className="col-md-12">
                        <div className="back-btn" onClick={()=>{
                            window.history.back()
                        }}>
                        <i className="bi bi-arrow-left" ></i> Back
                        </div>
                        <div className="heading-section">
                            <h5 className="title heading-title">Valuation Results</h5>
                            </div>
                            <ResultTable />
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TradeValuationResult;
// import axios from 'axios';
// const dataForToken = localStorage.getItem('okta-token-storage');
// const parsedData = JSON.parse(dataForToken);
// let authToken = parsedData && parsedData.idToken && parsedData.idToken.idToken;
// console.log('authToken', authToken);
// const axiosInstance = axios.create({
//   baseURL: "http://10.10.9.24:5000/users",
//   headers: {
//     'Authorization': authToken
//   }
// });

// // Function for making a GET request
// const get = async (url) => {
//   try {
//     const response = await axiosInstance.get(url);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// // Function for making a POST request
// const post = async (url, data) => {
//   try {
//     const response = await axiosInstance.post(url, data);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// // Function for making a PUT request
// const put = async (url, data) => {
//   try {
//     const response = await axiosInstance.put(url, data);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// // Function for making a DELETE request
// const remove = async (url) => {
//   try {
//     const response = await axiosInstance.delete(url);
//     return response.data;
//   } catch (error) {
//     console.error(error);
//   }
// };

// export { get, post, put, remove };

import axios from 'axios';


class AxiosHelper {
  constructor() {
    this.instance = axios.create({
      // baseURL: 'http://10.10.9.24:5000/users',
      // timeout: 5000,
      baseURL: '',
      timeout: 100000,
    });
    // delete this.instance.defaults.headers.common['crossDomain'];
  }
  async get(endpoint, params) {
    try {
      const response = await this.instance.get(endpoint, {
        params,
        headers: this.getAuthHeader(),
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async post(endpoint, data) {
    try {
      const response = await this.instance.post(endpoint, data, {
        headers: this.getAuthHeader(),
      });
      return response;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async put(endpoint, data) {
    try {
      const response = await this.instance.put(endpoint, data, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  async delete(endpoint) {
    try {
      const response = await this.instance.delete(endpoint, {
        headers: this.getAuthHeader(),
      });
      return response.data;
    } catch (error) {
      console.error(error);
      throw error;
    }
  }

  getAuthHeader() {
    const headers = {};
    const dataForToken = localStorage.getItem('okta-token-storage');
    const parsedData = JSON.parse(dataForToken);
    let authToken = parsedData && parsedData.idToken && parsedData.idToken.idToken;
    if (authToken) {
      headers.Authorization = authToken;
    }
    return headers;
  }
}

export default new AxiosHelper();

// Usage:
/*
async function fetchUsers() {
  try {
    const users = await AxiosHelper.get('/users');
    console.log(users);
  } catch (error) {
    console.error(error);
  }
}

async function createUser(user) {
  try {
    const response = await AxiosHelper.post('/users', user);
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}

async function updateUser(userId, userData) {
  try {
    const response = await AxiosHelper.put(`/users/${userId}`, userData);
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}

async function deleteUser(userId) {
  try {
    const response = await AxiosHelper.delete(`/users/${userId}`);
    console.log(response);
  } catch (error) {
    console.error(error);
  }
}
*/


import React from 'react';
import axios from 'axios';
import {
    BarLoader
} from 'react-spinners'


function FileLoader(props) {
    if (props.loading) {
        return (
            <div style={{
                // position: 'absolute',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                zIndex: 999999
            }}>
                <BarLoader
                    color={'#2f8ec7'} width={'750px'} loading={true} />
            </div>
        );
    }
}

export default React.memo(FileLoader);
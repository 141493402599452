import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import NavBar from "../nav/NavBar";
import axios from "axios";
import { API } from "../../store/config/ApiUrl";
import axiosHelper from "../utils/axiosHelper";
import { } from "react-router-dom";
import DataTable from "react-data-table-component";
import moment from "moment";
import { toast } from "react-toastify";
import Loader from "../loader/Loader";

let varSortDirection = "-1";
let VarSortBy = "CREATED_AT";

const TradeDetail = () => {
    let location = useLocation();

    const [tradeData, setTradeData] = useState([]);
    const [tradeDataById, setTradeDataById] = useState([]);
    const [selectedRow, setSelectedRow] = useState(null);
    const [loader, setLoader] = useState(true);
    const [totalRows, setTotalRows] = useState(0);
    const [perPage, setPerPage] = useState(10);
    const [sortBy, setSortBy] = useState("CREATED_AT");
    const [sortDirection, setSortDirection] = useState("-1")
    const [page, setPage] = useState(1);


    const queryParams = new URLSearchParams(location.search);
    const docId = queryParams.get('doc_id');

    const columns = [
        {
            name: "ID",
            selector: tradeData => tradeData?.["_id"] || "NA",
            wrap: true,
            width: "170px",
            cell: (row) => {
                return (
                    // <a href={`/tradeDetail/${row.id}`} >{row.id}</a>
                    <button type="button" onClick={() => {
                        setSelectedRow(row)
                    }} className="value-popup" data-bs-toggle="modal" data-bs-target="#exampleModal">
                        {row._id}
                    </button>

                )
            },

        },
        {
            name: "DOC ID",
            selector: tradeData => tradeData?.["DOC_Id"] || "NA",
            width: "130px",
            wrap: true,
        },
        {
            name: "Created At",
            selector: tradeData => tradeData?.["CREATED_AT"] || "NA",
            cell: row => moment(row?.CREATED_AT).format("YYYY-MM-DD"),
            width: "140px",
            wrap: true,
            sortable: true,
            sortField: 'CREATED_AT',
        },
        {
            name: "Entity Code",
            selector: tradeData => tradeData?.["ENTITY_CODE"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Action",
            selector: tradeData => tradeData?.["ACTION"] || "NA",
        },
        {
            name: "Asset Class",
            selector: tradeData => tradeData?.["ASSET_CLASS"] || "NA",
            width: "150px",
        },
        {
            name: "Portfolio",
            selector: tradeData => tradeData?.PORTFOLIO || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Snap",
            selector: tradeData => tradeData?.SNAP || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Custom Id 1",
            selector: tradeData => tradeData?.["CUSTOM_ID_1"] || "NA",
            width: "150px",
        },
        {
            name: "Custom Id 2",
            selector: tradeData => tradeData?.["CUSTOM_ID_2"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "User Code",
            selector: tradeData => tradeData?.["USER_CODE"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Ps Trade Id",
            selector: tradeData => tradeData?.["PS_TRADE_ID"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Fixed Rate",
            selector: tradeData => tradeData?.["FIXED_RATE"] || "NA",
            width: "150px",
        },
        {
            name: "Valuation Frequency",
            selector: tradeData => tradeData?.VALUATION_FREQUENCY || "NA",
            wrap: true,
            width: "190px",
        },
        {
            name: "Val Start Date",
            selector: tradeData => tradeData?.VAL_START_DATE && moment(tradeData?.VAL_START_DATE).format('YYYY-MM-DD') || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Val End Date",
            selector: tradeData => tradeData?.VAL_END_DATE && moment(tradeData?.VAL_END_DATE).format('YYYY-MM-DD') || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Hist Start Date",
            selector: tradeData => tradeData?.HIST_START_DATE && moment(tradeData?.HIST_START_DATE).format('YYYY-MM-DD') || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Hist End Date",
            selector: tradeData => tradeData?.HIST_END_DATE && moment(tradeData?.HIST_END_DATE).format('YYYY-MM-DD') || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Hist Val Only",
            selector: tradeData => tradeData?.HIST_VAL_ONLY || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Instrument Type",
            selector: tradeData => tradeData?.["INSTRUMENT_TYPE"] || "NA",
            width: "160px",
            wrap: true,
        },
        {
            name: "Leg1 Currency",
            selector: tradeData => tradeData?.["LEG1_CURRENCY"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Leg1 Day Count",
            selector: tradeData => tradeData?.["LEG1_DAY_COUNT"] || "NA",
            width: "150px",
        },
        {
            name: "Leg1 Fixing",
            selector: tradeData => tradeData?.["LEG1_FIXING"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Leg1 Maturity Date",
            selector: tradeData => tradeData?.LEG1_MATURITY_DATE && moment(tradeData?.LEG1_MATURITY_DATE).format('YYYY-MM-DD') || "NA",
            width: "180px",
            wrap: true,
        },
        {
            name: "Leg1 Notional",
            selector: tradeData => tradeData?.["LEG1_NOTIONAL"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Leg1 Pay Frequency",
            selector: tradeData => tradeData?.["LEG1_PAY_FREQUENCY"] || "NA",
            width: "180px",
            wrap: true,
        },
        {
            name: "Leg2 Currency",
            selector: tradeData => tradeData?.["LEG2_CURRENCY"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Leg2 Day Count",
            selector: tradeData => tradeData?.["LEG2_DAY_COUNT"] || "NA",
            width: "150px",
            wrap: true
        },
        {
            name: "Leg2 Fixing",
            selector: tradeData => tradeData?.["LEG2_FIXING"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Leg2 Maturity Date",
            selector: tradeData => tradeData?.LEG2_MATURITY_DATE && moment(tradeData?.LEG2_MATURITY_DATE).format('YYYY-MM-DD') || "NA",
            width: "180px",
            wrap: true,
        },
        {
            name: "Leg2 Notional",
            selector: tradeData => tradeData?.["LEG2_NOTIONAL"] || "NA",
            width: "150px",
            wrap: true,
        },
        {
            name: "Leg2 Pay Frequency",
            selector: tradeData => tradeData?.["LEG2_PAY_FREQUENCY"] || "NA",
            width: "180px",
            wrap: true,
        },
        {
            name: "Pay Leg",
            selector: tradeData => tradeData?.["PAY_LEG"] || "NA",
            wrap: true,
        },
        {
            name: "Trade Start Date",
            selector: tradeData => tradeData.TRADE_START_DATE && moment(tradeData?.["TRADE_START_DATE"]).format('YYYY-MM-DD') || "NA",
            width: "160px",
            wrap: true,
        },
        {
            name: "Created By",
            selector: tradeData => tradeData?.["CREATED_BY_NAME"] || "NA",
            //cell: row => moment(row?.CREATED_BY).format('MMMM Do YYYY, h:mm:ss a'),
            width: "140px",
            wrap: true,
        }
    ]

    const customStyles = {
        headCells: {
            style: {
                background: '#2f8ec7', // Change this to your desired header color
                color: '#fff', // Text color for the header
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'flex-start',
            },
        },
        cells: {
            style: {
                display: 'flex',
                justifyContent: 'flex-start', // Center align all values in the table cells
                alignItems: 'center',
            },
        },
    };

    useEffect(() => {
        if (docId == null) {
            getAllTradesOfUserDetails(page, perPage, sortBy, sortDirection)
        }
        if (docId !== null) {
            getTradeByDocId(docId, page, perPage, sortBy, sortDirection)
        }
    }, [docId]);


    // Function to get trades of a perticular user
    const getAllTradesOfUserDetails = async (page, perPage, sortBy, sortDirection) => {
        const dataForToken = localStorage.getItem('okta-token-storage');
        const parsedData = JSON.parse(dataForToken);
        let userID = parsedData.accessToken?.claims?.uid;
        try {
            setLoader(true)
            const result = await axiosHelper.get(`${API.getData}/${userID}?page=${page}&per_page=${perPage}&sort=${sortBy}&order=${sortDirection}`);
            if (result?.status === 200) {
                setLoader(false)
                setTradeData(result?.data?.result?.data?.tradeData);
                setTotalRows(result?.data?.result.data.totalCount);
            } else if (result.status === 204) {
                setTradeData([]);
                setLoader(false)
            }
        } catch (err) {
            if (err?.response?.data) {
                toast.error(err.response?.data?.error.message)
            }
            else {
                toast.error(err?.message);
            }
        }
    };

    // Function to get trade by doc id
    const getTradeByDocId = async (docId, page, perPage, sortBy, sortDirection) => {
        try {
            setLoader(true)
            let result = await axios.get(`${API.getTradesByDocId}/${docId}?page=${page}&per_page=${perPage}&sort=${sortBy}&order=${sortDirection}`)
            if (result.status === 200) {
                setTradeDataById(result?.data?.result?.data?.tradeData);
                setTotalRows(result?.data?.result?.data?.totalCount);
                setLoader(false)
            } else if (result?.status === 204) {
                setTradeDataById([]);
                setLoader(false)
            }
        } catch (error) {
            if (error?.response) {
                toast.error(error.response?.data?.error.message)
            }
            else if (error?.message) {
                toast.error(error?.message)
            }
        }
    }

    const handlePerRowsChangeAllTrades = async (newPerPage, page) => {
        getAllTradesOfUserDetails(page, newPerPage, sortBy, sortDirection);
        setPerPage(newPerPage);
    };

    const handlePerRowsChangeTradeByDocId = async (newPerPage, page) => {
        getTradeByDocId(docId, page, newPerPage, sortBy, sortDirection);
        setPerPage(newPerPage);
    };

    const handlePageChangeAllTrades = currentPage => {
        getAllTradesOfUserDetails(currentPage, perPage, VarSortBy ? VarSortBy : "", varSortDirection ? varSortDirection : "");
        setPage(currentPage);
    };

    const handlePageChangeTradeByDocId = currentPage => {
        getTradeByDocId(docId, currentPage, perPage, VarSortBy ? VarSortBy : "", varSortDirection ? varSortDirection : "");
        setPage(currentPage);
    };

    const handleSortAllTrades = async (column, sortDirection) => {
        let direction = sortDirection === "asc" ? "1" : sortDirection === "desc" ? "-1" : ""
        varSortDirection = direction;
        VarSortBy = column?.sortField;
        if (page === 1) {
            getAllTradesOfUserDetails(page, perPage, column?.sortField, direction);
        }
        setSortBy(column?.sortField);
        setSortDirection(direction)
    }

    const handleSortTradeByDocId = async (column, sortDirection) => {
        let direction = sortDirection === "asc" ? "1" : sortDirection === "desc" ? "-1" : ""
        varSortDirection = direction;
        VarSortBy = column?.sortField;
        if (page === 1) {
            getTradeByDocId(docId, page, perPage, column?.sortField, direction);
        }
        setSortBy(column?.sortField);
        setSortDirection(direction)
    }

    return (
        <>
            <NavBar />

            <div className="modal fade" id="exampleModal" tabIndex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
                <div className="modal-dialog modal-dialog-centered modal-trade-content">
                    <div className="modal-content modal-upload-content">
                        <div className="modal-header">
                            <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                        </div>
                        <div className="modal-body">
                            {/* ============================================= */}
                            <div className="trade-tb-content">
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="heading-section">
                                            <h5 className="title heading-title">{selectedRow?._id}</h5>
                                            <div className="">
                                                <span className="trade-view-date"><i class="bi bi-calendar4-range"></i> Trade Start Date: <strong>{selectedRow?.TRADE_START_DATE && moment(selectedRow?.TRADE_START_DATE).format("YYYY-MM-DD")}</strong></span><span className="snap-time"><i class="bi bi-clock"></i> Snap: <span className="time">{selectedRow?.SNAP}</span></span>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>PS TRADE ID</h5>
                                            {selectedRow && selectedRow?.PS_TRADE_ID || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>ACTION</h5>
                                            {selectedRow?.ACTION || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>ASSET CLASS</h5>
                                            {selectedRow?.ASSET_CLASS || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>ENTITY CODE</h5>
                                            {selectedRow?.ENTITY_CODE || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>CUSTOM ID 1</h5>
                                            {selectedRow?.CUSTOM_ID_1 || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>CUSTOM ID 2</h5>
                                            {selectedRow?.CUSTOM_ID_2 || "NA"}
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>INSTRUMENT TYPE</h5>
                                            {selectedRow?.INSTRUMENT_TYPE || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>PAY LEG</h5>
                                            {selectedRow?.PAY_LEG || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>PORTFOLIO</h5>
                                            {selectedRow?.PORTFOLIO || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>USER CODE</h5>
                                            {selectedRow?.USER_CODE || "NA"}
                                        </div>
                                    </div>
                                    <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                        <div className="custom-tb-trade">
                                            <h5>FIXED RATE</h5>
                                            {selectedRow?.FIXED_RATE || "NA"}
                                        </div>
                                    </div>



                                </div>

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="vf-content">
                                            <h2>VALUATION</h2>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="custom-tb-trade">
                                                        <h5>VALUATION FREQUENCY</h5>
                                                        {selectedRow?.VALUATION_FREQUENCY || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="custom-tb-trade">
                                                        <h5>VALUATION START DATE</h5>
                                                        {selectedRow?.VAL_START_DATE && moment(selectedRow?.VAL_START_DATE).format('YYYY-MM-DD') || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="custom-tb-trade">
                                                        <h5>VALUATION END DATE</h5>
                                                        {selectedRow?.VAL_END_DATE && moment(selectedRow?.VAL_END_DATE).format('YYYY-MM-DD') || "NA"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="vf-content">
                                            <h2>HIST</h2>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <div className="custom-tb-trade">
                                                        <h5>HIST VAL ONLY</h5>
                                                        {selectedRow?.HIST_VAL_ONLY || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="custom-tb-trade">
                                                        <h5>HIST START DATE</h5>
                                                        {selectedRow?.HIST_START_DATE && moment(selectedRow?.HIST_START_DATE).format('YYYY-MM-DD') || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-md-4">
                                                    <div className="custom-tb-trade">
                                                        <h5>HIST END DATE</h5>
                                                        {selectedRow?.HIST_END_DATE && moment(selectedRow?.HIST_END_DATE).format('YYYY-MM-DD') || "NA"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="vf-content">
                                            <h2>LEG1</h2>
                                            <div className="row">
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG1 DAY COUNT</h5>
                                                        {selectedRow?.LEG1_DAY_COUNT || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG1 CURRENCY</h5>
                                                        {selectedRow?.LEG1_CURRENCY || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG1 MATURITY DATE</h5>
                                                        {selectedRow?.LEG1_MATURITY_DATE && moment(selectedRow?.LEG1_MATURITY_DATE).format('YYYY-MM-DD') || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG1 PAY FREQUENCY</h5>
                                                        {selectedRow?.LEG1_PAY_FREQUENCY || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG1 NOTIONAL</h5>
                                                        {selectedRow?.LEG1_NOTIONAL || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG1 FIXING</h5>
                                                        {selectedRow?.LEG1_FIXING || "NA"}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="vf-content">
                                            <h2>LEG2</h2>
                                            <div className="row">
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG2 DAY COUNT</h5>
                                                        {selectedRow?.LEG2_DAY_COUNT || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG2 CURRENCY</h5>
                                                        {selectedRow?.LEG2_CURRENCY || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG2 MATURITY DATE</h5>
                                                        {selectedRow?.LEG2_MATURITY_DATE && moment(selectedRow?.LEG2_MATURITY_DATE).format('YYYY-MM-DD') || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG2 PAY FREQUENCY</h5>
                                                        {selectedRow?.LEG2_PAY_FREQUENCY || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG2 NOTIONAL</h5>
                                                        {selectedRow?.LEG2_NOTIONAL || "NA"}
                                                    </div>
                                                </div>
                                                <div className="col-xl-2 col-lg-2 col-md-3 col-sm-3">
                                                    <div className="custom-tb-trade">
                                                        <h5>LEG2 FIXING</h5>
                                                        {selectedRow?.LEG2_FIXING || "NA"}
                                                    </div>
                                                </div>

                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            {/* ============================================= */}



                        </div>
                        <div className="modal-footer">
                            <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                        </div>
                    </div>
                </div>
            </div>
            <div className="main-content">
                <div className="container db-content">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-md-12">
                            <div className="back-btn" onClick={() => {
                                window.history.back()
                            }}>
                                <i className="bi bi-arrow-left" ></i> Back
                            </div>
                            <div className="heading-section">
                                <h5 className="title heading-title">View Trades</h5>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-12">
                            <div className="card tableLayout table-main tb-layout-content">
                                <Loader loading={loader} />
                                {
                                    (tradeData || tradeDataById) &&
                                    <DataTable
                                        columns={columns}
                                        data={tradeData.length > 0 ? tradeData : (tradeDataById.length ? tradeDataById : [])}
                                        fixedHeaderScrollHeight='100vh'
                                        fixedHeader
                                        striped
                                        highlightOnHover
                                        customStyles={customStyles}
                                        pagination
                                        paginationServer
                                        paginationTotalRows={totalRows}
                                        onChangeRowsPerPage={docId == null ? handlePerRowsChangeAllTrades : handlePerRowsChangeTradeByDocId}
                                        onChangePage={docId == null ? handlePageChangeAllTrades : handlePageChangeTradeByDocId}
                                        onSort={docId == null ? handleSortAllTrades : handleSortTradeByDocId}
                                        sortServer
                                    />
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default TradeDetail;